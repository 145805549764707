import  React from 'react';
import logo from '../../../images/skills/js.png';

const Javascript =  () => {
  return (
    <div className={'skill'}>
        <img src={logo}/>
      <h2>Javscript</h2>
    </div>
  )
}
export default Javascript