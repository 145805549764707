import  React from 'react';
import logo from '../../../images/skills/node.svg';

const Node =  () => {
  return (
    <div className={'skill'}>
      <img src={logo}/>
      <h2>NodeJS</h2>
    </div>
  )
}
export default Node