import  React from 'react';
import logo from '../../../images/skills/sass.png';

const Sass =  () => {
  return (
    <div className={'skill'}>
      <img src={logo}/>
      <h2>Sass</h2>
    </div>
  )
}
export default Sass