import  React from 'react';
import logo from '../../../images/skills/git.png';

const Git =  () => {
  return (
    <div className={'skill'}>
      <img src={logo}/>
      <h2>Git</h2>
    </div>
  )
}
export default Git