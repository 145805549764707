import React from 'react';
import Layout from "../components/Layout"
import Skills from "../components/Skills"


const blogPage =  () => {

  return (
    <Layout>
      <div className="container">
        <Skills/>
      </div>
    </Layout>
    )
}
export default blogPage;
