import  React from 'react';
import logo from '../../../images/skills/mysql.svg';

const Sql =  () => {
  return (
    <div className={'skill'}>
      <img src={logo}/>
      <h2>MySQL</h2>
    </div>
  )
}
export default Sql