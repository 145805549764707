import  React from 'react';
import logo from '../../../images/skills/laravel.png'

const Laravel =  () => {
  return (
    <div className={'skill'}>
      <img src={logo}/>
      <h2>Laravel</h2>
    </div>
  )
}
export default Laravel