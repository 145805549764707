import  React from 'react';
import logo from '../../../images/skills/post.png';

const PostGresql =  () => {
  return (
    <div className={'skill'}>
      <img src={logo}/>
      <h2>PostgreSQL</h2>
    </div>
  )
}
export default PostGresql